<template>
    <h1>Cryptos</h1>

    <div class="cryptos">
        <ul>
            <li
                v-for="item in list"
                :key="item"
            >
                <router-link :to="`/crypto/${item.name.toLowerCase()}-${item.symbol.toLowerCase()}`">
                    <h2>
                        {{ item.name }}
                    </h2>

                    <img
                        :src="`/img/cryptos/${item.name.toLowerCase()}-${item.symbol.toLowerCase()}.png`"
                        :alt="`${item.symbol}-logo`"
                    >

                    <h3>
                        {{ item.symbol }}
                    </h3>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'Cryptos',
    data() {
        return {
            list: [
                {
                    name: 'Bitcoin',
                    symbol: 'BTC'
                },
                {
                    name: 'Ethereum',
                    symbol: 'ETH'
                },
                {
                    name: 'Unibright',
                    symbol: 'UBT'
                },

            ]
        }
    },
}

</script>

<style lang="scss">
.cryptos {
    h2 {
        margin-top: 6px;
        font-size: 18px;

        @media (min-width: 1024px) {
            font-size: 22px;
        }
    }

    h3 {
        font-size: 12px;

        @media (min-width: 1024px) {
            font-size: 16px;
        }
    }

    a {
        color: white;
        cursor: pointer;
    }

    ul {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    li {
        margin-bottom: 24px;
        padding: 0 12px;
        border-radius: 6px;
        background-color: rgba(0, 0, 0, .1);
        text-align: center;
        list-style-type: none;

        @media (min-width: 375px) {
            padding: 0 24px;
        }

        @media (min-width: 414px) {
            padding: 0 30px;
        }

        @media (min-width: 1024px) {
            padding: 12px 48px;
        }
    }

    img {
        width: 100%;
        object-fit: contain;

        @media (min-width: 1024px) {
            min-width: 150px;
        }
    }
}
</style>
